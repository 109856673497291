/**
 * 聚合接口
 */

import { apiGet, apiPost } from '../utils/http';

export async function requestHotCarsList(params = {}) {
  const api = 'book/hotcars';
  return await apiGet(api, params);
}

export async function requestUserPackage(data = {}) {
  const api = 'book/coupons/userPackage';
  return await apiPost(api, data);
}

export async function requestCollectedCouponsList(data = {}) {
  const api = 'book/coupons/collectedCouponsList';
  return await apiPost(api, data);
}

export async function requestReceviceCoupons(data = {}) {
  const api = 'book/coupons/receviceCoupons';
  return await apiPost(api, data);
}


export async function requestLogout(data = {}) {
  const api = 'station/logout';
  return await apiPost(api, data);
}
